<template>
    <div class="role-wrapper">
        <div class="role-header">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/admin/rolepermissions' }">角色权限管理</el-breadcrumb-item>
                <el-breadcrumb-item>角色管理</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <el-table :data="roleList" border style="width: 100%; margin-top: 20px; flex: 1" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#13131B', background: '#F2F3FA'}"
                  :cell-style="{fontSize: '14px',color: '#343441'}">
            <el-table-column prop="name" align="center" label="角色名称"></el-table-column>
            <el-table-column prop="desc" align="center" label="描述"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link :underline="false" class="link-blue" type="primary" @click="addPermits(scope.row)">分配权限</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pager-center" style="margin-top: 10px;"
                       :current-page="rolePages.currentPageNum"
                       :page-size="rolePages.eachPageNum"
                       :total="rolePages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="routeCurrentChange">
        </el-pagination>
        <!--创建/编辑角色 弹窗-->
        <el-dialog :title="roleOperTitle" :visible.sync="addRouteDialog" width="500px" custom-class="dialog-blue"
                   @close="resetForm()" :close-on-click-modal="false">
            <el-scrollbar :native="false" class="permits-item">
                <el-form :model="addRouteForm" ref="addRouteForm" label-position="right">
                    <el-form-item prop="permits">
                            <el-tree
                                    :data="routeData"
                                    show-checkbox
                                    node-key="c_id"
                                    default-expand-all
                                    :default-checked-keys="checkedRoleIds"
                                    ref="tree"
                                    :props="defaultProps">
                            </el-tree>
                    </el-form-item>
                </el-form>
            </el-scrollbar>
            <div slot="footer" class="dialog-footer" style="text-align: center">
                <el-button size="medium" class="btn-blue" @click="saveForm('addRouteForm')">确 定</el-button>
                <el-button size="medium" @click="addRouteDialog = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "RoleManage",
        data() {
            return {
                //角色分组列表
                roleList: [],
                //分页
                rolePages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                checkedRoleIds: [],
                //创建/编辑角色分组弹窗标题
                roleOperTitle: '分配权限',
                addRouteDialog: false,
                //菜单分组数据
                addRouteForm: {
                    id: '',
                    route_name: '',
                    route_desc: ''
                },
                //权限分组
                routeData: [],
                defaultProps: {
                    id: 'c_id',
                    children: 'children',
                    label: 'c_name'
                }
            }
        },
        mounted() {
            this.getRouteList();
            this.getAllRouteList();
        },
        methods: {
            //获取角色分组列表
            getRouteList() {
                let param = {
                    type: 'page',
                    page: this.rolePages.currentPageNum,
                    limit: this.rolePages.eachPageNum
                }
                this.$http.axiosGetBy(this.$api.RGList, param, (res) => {
                    if (res.code === 200) {
                        this.roleList = res.data.data;
                        this.rolePages.total = res.data.total;
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //获取所有路由菜单
            getAllRouteList() {
                this.$http.axiosGet(this.$api.getAMR, (res) => {
                    if (res.code === 200) {
                        this.routeData = res.data;
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //分配权限
            addPermits(row) {
                this.addRouteDialog = true;
                this.addRouteForm.route_name = row.name;
                this.addRouteForm.route_desc = row.desc;
                this.addRouteForm.id = row.id;
                this.$http.axiosGetBy(this.$api.getRG, {route_group_id: row.id}, (res) => {
                    if (res.code === 200) {
                        this.checkedRoleIds = ((res.data.permits).split("#")).map(Number);
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //分页
            routeCurrentChange(val) {
                this.getRouteList();
            },
            //保存角色分组
            saveForm(formName) {
                //选中的权限组，用#号隔开
                let permits=[];
                permits = this.$refs.tree.getCheckedKeys();
                //去除undefined后的结果
                let selectPermits = [];
                for(let i=0;i<permits.length;i++){
                    if(typeof(permits[i])!='undefined'){
                        selectPermits.push(permits[i]);
                    }
                }

                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData();
                        formData.append('role_name', this.addRouteForm.route_name);
                        formData.append('is_system', '1');
                        formData.append('desc', this.addRouteForm.route_desc);
                        formData.append('permits', selectPermits.join("#"));
                        formData.append('route_group_id', this.addRouteForm.id);
                        this.$http.axiosPost(this.$api.saveRG, formData, (res) => {
                            if (res.code === 200) {
                                this.$message.success('成功分配权限！');
                                this.getRouteList();
                                this.addRouteDialog = false;
                                this.resetForm();
                            } else {
                                this.$message.warning('分配权限失败！')
                            }
                        }, (err) => {
                            console.log(err)
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            //关闭菜单分组弹窗，要清除默认选项
            resetForm() {
                this.$refs.tree.setCheckedKeys([]);
            },

        }
    }
</script>

<style scoped lang="scss">
    .role-wrapper {
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .role-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .el-breadcrumb {
                line-height: 40px;
            }
        }
        .el-link + .el-link {
            margin-left: 10px;
        }
        /deep/ .el-textarea .el-textarea__inner {
            height: 84px;
            resize: none;
        }
        .el-checkbox-group {
            margin-left: 22px;
        }
        /deep/ .el-tree-node.is-expanded>.el-tree-node__children {
            display: flex;
            flex-wrap: wrap;
        }
        .permits-item {
            height: 100%;
            /deep/ .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }
        .el-form-item {
            margin-bottom: 0;
        }
        /deep/ .el-dialog {
            height: 440px;
            min-height: 440px;
            max-height: 440px;
            .el-dialog__body {
                height: 260px;
            }
        }
    }
</style>